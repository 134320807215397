import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Avatar from '@uz/unitz-components-web/Avatar';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomRow = styled(Col)`
  position: relative;
  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: ${gstyles.colors.border};
  }
`;
const View8 = () => {
  return (
    <DIV className="component">
      <Row className="py-10" justify="center" align="middle">
        <Col span={22}>
          <div className="flex items-center">
            <div className="flex items-center">
              <Avatar size={232} src={MemberFormatter.avatarUrl(ctx)(ctx.get('itemData'))} />
              <CustomRow style={{ padding: '0 40px 0 40px' }}>
                <div className="text-sub font-semibold" style={{ fontSize: 32 }}>
                  {MemberFormatter.fullName(ctx)(ctx.get('itemData'))}
                </div>
                <div className="my-2 text-sub text-xl font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.type')}: Teacher
                </div>
                <div className="my-2 text-sub text-xl font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolMember.start_date')}:{' '}
                  {MemberFormatter.startDate(ctx)(ctx.get('itemData'))}
                </div>
                <div className="my-2 text-sub text-xl font-semibold flex items-center">
                  {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.status')}:
                  {!!ctx.get('itemData.is_active') ? (
                    <div className="ml-3 text-xs py-1 px-2 bg-brandb500 text-white500 rounded-lg">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.active')}
                    </div>
                  ) : (
                    <div className="ml-3 text-xs py-1 px-2 bg-background2 text-sub rounded-lg">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.disabled')}
                    </div>
                  )}
                </div>
              </CustomRow>
            </div>
            <div style={{ paddingLeft: 30 }}>
              <div className="my-2 text-sub text-xl font-semibold">
                {ctx.apply('i18n.t', 'ZoomToolTeacher.Attendance.present')}: N/A
              </div>
              <div className="my-2 text-sub text-xl font-semibold">
                {ctx.apply('i18n.t', 'ZoomToolTeacher.Attendance.absent')}: N/A
              </div>
              <div className="my-2 text-sub text-xl font-semibold">
                {ctx.apply('i18n.t', 'ZoomToolTeacher.Attendance.late')}: N/A
              </div>
              <div className="my-2 text-sub text-xl font-semibold">
                {ctx.apply('i18n.t', 'ZoomToolTeacher.Attendance.late')}: N/A
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </DIV>
  );
};

export default displayName(View8);
